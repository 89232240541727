import { Get, Post } from './BaseService';

import LoginDTO from '@/dtos/LoginDTO';
import UserDTO from '@/dtos/UserDTO';
import { ClientDTO } from '@/dtos/ClientDTO';

const UsuarioService = {
    Login: (data: LoginDTO) => {
        return Post<LoginDTO, UserDTO>('api/Auth', data);
    },
    GetAccessToken: (accessTokenid: string) => {
        return Get<any>(`/api/Auth/get-access-token/${accessTokenid}`);
    },
    getClient: (id: string) => {
        return Get<ClientDTO>(`/api/Client/${id}`);
    }
};
export default UsuarioService;