<template>
    <div class="min-h-screen bg-white flex items-center justify-end relative">
        <div
            class="
        w-5/12
        px-16
        bg-primary
        h-screen
        flex
        items-center
        top-0
        left-0
        fixed
      "
        >
            <div class="w-full max-w-sm" style="min-height: 320px">
                <h1 class="text-3xl font-bold text-white mb-6">
                    Uma forma segura para realizar o seu exame
                </h1>
                <p class="text-white">
                    Seu exame em formato on-line. Faça o seu exame de forma segura e sem
                    sair de casa.
                </p>
            </div>
        </div>

        <!-- Wrapper -->
        <div class="w-7/12 py-12" style="min-height: 320px">
            <div class="w-full max-w-sm 2xl:max-w-lg mx-auto">
                <h1 class="text-3xl font-bold mb-6">Bem-vindo</h1>
                <p class="font-medium">Olá, administrador!</p>
                <p class="mb-12">Para continuar, insira suas credenciais</p>

                <Form @onSubmit="submit">
                    <LoginInput
                        class="mb-4"
                        placeholder="Informe seu e-mail"
                        v-model="state.emailOrCpf"
                        name="email"
                        :rules="[required, validEmailOrCpf]"
                        :displayError="false"
                        @input="state.error = ''"
                    />

                    <LoginInput
                        placeholder="Digite sua senha"
                        :hasButton="true"
                        class="mb-4"
                        v-model="state.password"
                        type="password"
                        name="senha"
                        :rules="[required]"
                        :displayError="false"
                        @input="state.error = ''"
                        :loading="state.loading"
                        :buttonInError="state.error.length > 0"
                    />
                </Form>

                <div class="h-4">
                    <transition name="fade">
                        <p class="text-red-500 text-sm" v-if="state.error.length > 0">
                            {{ state.error }}
                        </p>
                    </transition>
                </div>
            </div>
        </div>

        <!-- Logo -->
        <img src="@/assets/img/logo-white.svg" class="h-10 fixed top-8 left-8" />
    </div>
</template>

<script lang='ts'>
import { defineComponent, reactive, watch } from "vue";
import { required, validEmailOrCpf } from "@/utils/validationFunctions";
import { useRouter } from "vue-router";
import $http from "@/plugins/http";
// import $httpID from "@/plugins/httpID";
import vm from "@/viewModels/MainViewModel";

import Form from "@/components/Form.vue";
import LoginInput from "@/components/LoginInput.vue";
import UsuarioService from "@/services/UsuarioService";

interface LoginFormViewState {
    emailOrCpf: string;
    password: string;
    loading: boolean;
    error: string;
}

const LoginMainView = defineComponent({
    components: { Form, LoginInput },
    setup() {
    // Hooks
        const { replace } = useRouter();

        // State
        const state = reactive<LoginFormViewState>({
            emailOrCpf: "",
            password: "",
            loading: false,
            error: "",
        });

        watch(
            () => state.emailOrCpf,
            (newValue, oldValue) => {
                const isCpf = /\d{11}/.test(newValue);
                if (isCpf && newValue.length == 11) {
                    // console.log(newValue);
                    const cpf =
            newValue.substring(0, 3) +
            "." +
            newValue.substring(3, 6) +
            "." +
            newValue.substring(6, 9) +
            "-" +
            newValue.substring(9, 11);
                    state.emailOrCpf = cpf;
                }
                if (/^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(oldValue)) {
                    const value = newValue.replace(new RegExp("[.-]", "gi"), "");
                    state.emailOrCpf = value;
                }
            }
        );

        // Submit
        const submit = async (data: { isValid: boolean; errors: string[] }) => {
            state.error = "";
            if (data.isValid) {
                try {
                    state.loading = true;

                    // Realizar a requisição
                    const [request] = UsuarioService.Login({
                        emailOrCpf: state.emailOrCpf,
                        key: state.password,
                    });
                    const user = await request;

                    $http.defaults.headers["Authorization"] = "Bearer " + process.env.VUE_APP_API_TOKEN_EXAM;

                    const [request2] = UsuarioService.getClient(user.clientId);
                    const client = await request2;

                    // Verificar se é admin
                    if (user.roleDescription != "ADMIN" && user.roleDescription != "SUPER") {
                        throw "Usuário ou senha inválidos";
                    }
                    // Setar o usuário
                    $http.defaults.headers["Authorization"] = "Bearer " + user.token;
                    // $httpID.defaults.headers["Authorization"] = "Bearer " + user.token;
                    vm.user = user;
                    vm.client = client;
                    user.roleDescription == "ADMIN" && replace({ name: "admin" });
                    user.roleDescription == "SUPER" && replace({ name: "super" });
                } catch (error) {
                    state.error = error;
                    state.loading = false;
                }
            } else {
                state.error = data.errors[0];
            }
        };

        return { state, submit, required, validEmailOrCpf };
    },
});

export default LoginMainView;
</script>

<style>
</style>