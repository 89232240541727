
import { defineComponent, reactive, watch } from "vue";
import { required, validEmailOrCpf } from "@/utils/validationFunctions";
import { useRouter } from "vue-router";
import $http from "@/plugins/http";
// import $httpID from "@/plugins/httpID";
import vm from "@/viewModels/MainViewModel";

import Form from "@/components/Form.vue";
import LoginInput from "@/components/LoginInput.vue";
import UsuarioService from "@/services/UsuarioService";

interface LoginFormViewState {
    emailOrCpf: string;
    password: string;
    loading: boolean;
    error: string;
}

const LoginMainView = defineComponent({
    components: { Form, LoginInput },
    setup() {
    // Hooks
        const { replace } = useRouter();

        // State
        const state = reactive<LoginFormViewState>({
            emailOrCpf: "",
            password: "",
            loading: false,
            error: "",
        });

        watch(
            () => state.emailOrCpf,
            (newValue, oldValue) => {
                const isCpf = /\d{11}/.test(newValue);
                if (isCpf && newValue.length == 11) {
                    // console.log(newValue);
                    const cpf =
            newValue.substring(0, 3) +
            "." +
            newValue.substring(3, 6) +
            "." +
            newValue.substring(6, 9) +
            "-" +
            newValue.substring(9, 11);
                    state.emailOrCpf = cpf;
                }
                if (/^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(oldValue)) {
                    const value = newValue.replace(new RegExp("[.-]", "gi"), "");
                    state.emailOrCpf = value;
                }
            }
        );

        // Submit
        const submit = async (data: { isValid: boolean; errors: string[] }) => {
            state.error = "";
            if (data.isValid) {
                try {
                    state.loading = true;

                    // Realizar a requisição
                    const [request] = UsuarioService.Login({
                        emailOrCpf: state.emailOrCpf,
                        key: state.password,
                    });
                    const user = await request;

                    $http.defaults.headers["Authorization"] = "Bearer " + process.env.VUE_APP_API_TOKEN_EXAM;

                    const [request2] = UsuarioService.getClient(user.clientId);
                    const client = await request2;

                    // Verificar se é admin
                    if (user.roleDescription != "ADMIN" && user.roleDescription != "SUPER") {
                        throw "Usuário ou senha inválidos";
                    }
                    // Setar o usuário
                    $http.defaults.headers["Authorization"] = "Bearer " + user.token;
                    // $httpID.defaults.headers["Authorization"] = "Bearer " + user.token;
                    vm.user = user;
                    vm.client = client;
                    user.roleDescription == "ADMIN" && replace({ name: "admin" });
                    user.roleDescription == "SUPER" && replace({ name: "super" });
                } catch (error) {
                    state.error = error;
                    state.loading = false;
                }
            } else {
                state.error = data.errors[0];
            }
        };

        return { state, submit, required, validEmailOrCpf };
    },
});

export default LoginMainView;
