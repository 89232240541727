
import { defineComponent, onMounted, reactive } from "vue";
import vm from "@/viewModels/MainViewModel";

import { useRouter } from "vue-router";
import Loading from "@/components/Loading.vue";
import UsuarioService from "@/services/UsuarioService";
import $http from "@/plugins/http";

interface RedirectViewState {
    loading: boolean;
    error: string;
}

const RedirectView = defineComponent({
    components: { Loading },
    setup() {
    // Hooks
        const { replace, push, currentRoute } = useRouter();

        // State
        const state = reactive<RedirectViewState>({
            loading: false,
            error: "",
        });

        // Submit
        const submit = async () => {
            state.error = "";
            try {
                state.loading = true;

                const accessToken = currentRoute.value.params.accessToken.toString();
                const proctoringId = currentRoute.value.params.proctoringId;

                $http.defaults.headers["Authorization"] = "Bearer " + process.env.VUE_APP_API_TOKEN_EXAM;

                // Realizar a requisição
                const [request] = UsuarioService.GetAccessToken(accessToken);
                const user = await request;
                // console.log(user);

                const [request2] = UsuarioService.getClient(user.clientId);
                const client = await request2;
                vm.client = client;

                // Setar o usuário
                $http.defaults.headers["Authorization"] = "Bearer " + user.token;
                vm.user = user;
                // console.log(proctoringId);
                if(proctoringId) {
                    // console.log("exames");
                    push({ name: 'exames', params: { id: proctoringId } });
                } else {
                    // console.log("admin");
                    replace({ name: "admin" });
                }
            } catch (error) {
                state.error = String(error);
                state.loading = false;
            }
        };

        onMounted(() => {
            submit();
        });

        return { state, submit };
    },
});

export default RedirectView;
